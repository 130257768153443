import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Usuario } from './../models/usuario.model';
import { AppSettings } from './../app.config';
import { map } from 'rxjs/operators';


@Injectable()
export class AuthService {

  private usuarioAutenticado: boolean = false;
  public alertaSenhaErrada: boolean = false;
  public cargo: string;

  constructor(private router: Router,
              private _http: HttpClient) { }

  fazerLogin(email: string, password: string): Observable<any>  {
    let headers = new HttpHeaders();
        headers = headers.set('Content-Type', 'application/json; charset=utf-8');
        headers.append('Access-Control-Allow-Methods', 'GET, POST, OPTIONS, PUT, PATCH, DELETE');
            
        return this._http.post<Usuario>(AppSettings.API_ENDPOINT,
          {email: email, password: password },
          { headers: headers, observe: 'response' })
            .pipe(
              map((response) => ({data: response.body, status: response.status}))
            )
          }

  logout(){
    localStorage.removeItem('currentUser');
    localStorage.removeItem('cargo');
    this.router.navigate(['']);
  }

  setUsuarioEstaAutenticado(condicao:boolean){
    this.usuarioAutenticado = condicao;
  }

  getUsuarioEstaAutenticado(){
    return this.usuarioAutenticado;
  }

}