import { LoadingModule } from './../loading/loading.module';
import { LoginRoutingModule } from './login.routing.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';



import { LoginComponent } from './login.component';

import { AuthService } from './../../services/auth.service';

import { ToastrModule } from 'ngx-toastr';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    LoginRoutingModule,
    ToastrModule.forRoot(),
    LoadingModule
  ],
  declarations: [
    LoginComponent
  ],
  providers:[
    AuthService
  ]
})
export class LoginModule { }