import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Usuario } from './../../models/usuario.model';
import { AuthService } from './../../services/auth.service';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})


export class LoginComponent implements OnInit {

  public alertaUsuarioIncorreto: boolean = false;

  public usuario: Usuario = new Usuario()

  subscription: Subscription;

  public response;

  public loading: boolean = false;


  token: string; //variável que é preenchida quando o usuário realiza login

  constructor(private router: Router,
              private authService: AuthService,
              private route: ActivatedRoute,
              private toastr: ToastrService
            ) {}

  ngOnInit() {}

  fazerLogin(email: string, password: string): void{

    this.loading = true
    
    this.subscription = this.authService.fazerLogin(email,password)
    
    .subscribe((res) => {
      let data = res.data; //aqui recebe o token
      let statusCode = res.status; //aqui eu recebo o status da requisição http (200 - ok) / != (200 toast de error)
      this.usuario.token = res.data.token; //meu objeto recebe o token
      this.usuario.cargo = res.data.cargo; //meu objeto recebe o cargo
      
      if(data == null){
        if(statusCode != 200){
          this.showToast();
          this.authService.setUsuarioEstaAutenticado(false);
          }
        }else{ //emite para o serviço que o usuário foi autenticado e que pode acessar as rotas do guard, redireciona para a home
          this.authService.setUsuarioEstaAutenticado(true);
          let token = data.token_jc; //aqui é recebido o token do usuário
          this.token = token; //aqui passa o valor do token usuário para a variável do componente
          localStorage.setItem('currentUser', this.token);
          localStorage.setItem('cargo', this.usuario.cargo);
          this.router.navigate(['admin']);
      }
    },
    (err: HttpErrorResponse) => { //em caso de erro na requisição, retorna um toast
      this.loading = false 
      if (err.error instanceof Error) {
        this.showToast();
        console.log('Client side error: Nome de usuário ou senha incorretos: ' + err);
      }else{
        this.showToast();
        console.log('Server side error: Ocorreu um erro no server-side: ' + err);
      }
    }
  )
}

showToast() { //Função que mostra o toast
  this.toastr.error('Nome de usuário ou senha incorreto!', 'Ei, temos um problema', {
    closeButton: true,
    progressAnimation: 'decreasing',
    progressBar: true,
    positionClass: 'toast-bottom-center'
  });
}


}