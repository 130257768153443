import { LoadingComponent } from './../loading/loading.component';
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
  ],
  declarations: [
    LoadingComponent
  ],
  providers:[
  ],
  exports:[
    LoadingComponent
  ]
})
export class LoadingModule { }