import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './components/login/login.component';

const appRoutes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'admin', loadChildren:'src/app/components/dashboard/dashboard.module#DashboardModule' } //Aplicado lazy loading(carregamento por)
];

@NgModule({
  imports:[RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule{}