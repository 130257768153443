import { LoadingModule } from './components/loading/loading.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app.routing.module';
import { MyMaterialDesignModule } from './../app.materialdesign.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { NgxMaskModule } from 'ngx-mask';

import { AppComponent } from './app.component';
import { LoginModule } from './components/login/login.module';
import { HashLocationStrategy, LocationStrategy, DatePipe } from '@angular/common';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    HttpClientModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MyMaterialDesignModule,
    MDBBootstrapModule.forRoot(),
    LoginModule,
    AppRoutingModule,
    NgxMaskModule.forRoot(),
    LoadingModule
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
})

export class AppModule { }